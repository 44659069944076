.letter-details-gray {
    font-size: 16px;
    color: rgb(117, 117, 117);
    text-align: left;
    letter-spacing: normal;
    font-family: Roboto;
    font-weight: 400;
    font-style: normal;
}

.display-date-filter-down-service-history {
    display: none !important;
}

.display-date-filte-up-service-history {
    display: initial !important;
}

.border-bottom-icon {
}

    .border-bottom-icon:hover {
        border-bottom: 2px solid white;
    }

.border-bottom-img {
}

    .border-bottom-img:hover {
        border-bottom: 2px solid red;
    }

.view-btn-filter-service-history-down {
    display: none !important;
}

.view-btn-filter-service-history-up {
    display: initial !important;
}

.message-error-load {
    width: 80% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 17px !important;
}

.letter-link-tracking {
    font-size: 16px !important;
    color: #00539F !important;
    text-align: left !important;
    font-family: "Roboto Bold", "Roboto Regular", "Roboto" !important;
    font-weight: 400 !important;
}

    .letter-link-tracking:hover {
        text-decoration: underline !important;
    }

.MuiPaper-rounded {
    border-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.position-icon-up-order {
    position: relative;
    top: -2px;
}

.position-icon-down-order {
    position: relative;
    top: 9px;
    right: 0.6rem
}

.style-icon-order {
    line-height: 0 !important;
    font-size: 1.4em !important;
    width: 0.18em !important;
    height: 0em !important;
    background-color: rgb(139, 183, 240);
    color: rgb(139, 183, 240)
}

    .style-icon-order.ordered {
        opacity: 1;
        color: #04509f;
    }

.margin-icons-order {
    margin-left: 4px
}

.ui.input > input {
    max-width: 80%;
}

.ui.table {
    border: none;
}

    .ui.table thead th {
        background: #ffffff;
    }

.ui.celled.table tr td, .ui.celled.table tr th {
    border-left: none;
    border-left-width: initial;
    border-left-style: none;
    border-left-color: initial;
}

.ui.menu .item:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    height: 100%;
    width: 0px;
    background: rgba(34,36,38,.1);
}

.ui.menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 1rem 0;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    background: #fff;
    font-weight: 400;
    border: 0;
    -webkit-box-shadow: 0 0px 0px 0 rgba(34,36,38,.15);
    box-shadow: 0 0px 0px 0 rgba(34,36,38,.15);
    border-radius: 0;
    min-height: 2.85714286em;
}

.ui.pagination.menu .item {
    min-width: 1.5em;
    line-height: 1.5em;
}

.ui.pagination.menu .active.item {
    border-top: none;
    border-top-width: initial;
    border-top-style: none;
    border-top-color: initial;
    background-color: #0572E6;
    color: white;
    border-radius: 1px;
    -webkit-box-shadow: 1px 1px 5px 1px rgba(150,150,150,1);
    -moz-box-shadow: 1px 1px 5px 1px rgba(150,150,150,1);
    box-shadow: 1px 1px 5px 1px rgba(150,150,150,1);
}

.ui.grid > * {
    padding-left: 0px;
    padding-right: 0px;
}

.MuiTableCell-head {
    color: #04509F !important;
    font-size: 20px !important;
    font-weight: 600 !important;
}

.ui.celled.table td a {
  color: #04509F;
}

.font-title-table {
    font-size: 20px;
}

    .font-title-table.padded {
        padding-left: 5px;
    }

.fix-padding-row {
    padding-bottom: 10px !important;
    padding-top: 0 !important;
}

.size-button-filters {
    width: 37%;
    height: 37px;
}

.position-title-filter-date {
}

.ui.grid > .row.filters > .column {
    padding-left: 10rem;
    padding-right: 10rem;
    padding-bottom: 0 !important;
}

.row.filters {
    padding-bottom: 5px !important;
}

@media screen and (max-width: 767px) {

    .display-date-filter-down-service-history {
        display: initial !important;
    }

    .display-date-filte-up-service-history {
        display: none !important;
    }
}


@media screen and (max-width: 724px) {

    .ui.input > input {
        max-width: 100%;
    }

    .date-filter {
        width: 100% !important;
        float: none !important;
    }

        .date-filter .ui.input {
            width: 100% !important;
        }

    .width-filter-status {
        width: 100% !important;
    }

    .width-filter-order-type {
        width: 100% !important;
    }

    .margin-column-status {
        margin-left: 50px;
    }

    .size-btn-service-history {
        height: 42px;
    }

    .lastColumn {
        text-align: center !important;
    }

    .ui.column.grid > [class*="eight wide"].column, .ui.grid > .column.row > [class*="eight wide"].column, .ui.grid > .row > [class*="eight wide"].column, .ui.grid > [class*="eight wide"].column {
        width: 53% !important;
    }
}

@media screen and (max-width: 991px) {
    .view-btn-filter-service-history-down {
        display: initial !important;
    }

    .view-btn-filter-service-history-up {
        display: none !important;
    }

    .max-width-date-filter-service-history {
        width: 100% !important;
    }
}

@media screen and (max-width: 1195px) and (min-width: 725px) {
    .max-width-filters-service-history {
        width: 100% !important;
    }

    .date-filter .ui.input {
        width: 125% !important;
    }

    .lastColumn {
        text-align: center !important;
    }
}

@media screen and (min-width: 1319px) {
    .margin-left-month-to {
        margin-left: 20px
    }
}

@media screen and (max-width: 1318px) {
    .display-filter-date {
        display: block
    }
}

@media screen and (min-width: 881px) {
    .width-filter-status {
        width: 50%;
    }

    .width-filter-order-type {
        width: 85%;
    }
}

@media screen and (max-width: 880px) {
    .width-filter-status {
        width: 80px;
        display: block
    }

    .width-filter-order-type {
        width: 90px;
    }
}

@media screen and (min-width: 1196px) {
    .date-filter {
        float: right;
        width: 65%;
    }
}

@media screen and (max-width: 1195px) {
    .date-filter {
        float: none;
        width: 65%;
    }
}

.date-filter .ui.input > input {
    height: 10px;
    border-radius: 0px;
}

.ui.table tfoot th {
    background-color: white !important;
}

.pageCount {
    font-weight: 700;
    color: #757575;
}

.letter-title-service-history-list {
    cursor: pointer !important;
    background: #f9fafb !important;
    text-align: inherit !important;
    color: #04509f !important;
    vertical-align: inherit !important;
    font-weight: 700 !important;
    text-transform: none !important;
    border-bottom: 1px solid rgba(34, 36, 38, 0.1) !important;
    border-left: none !important;
    font-size: 20px !important;
}

@media all and (-ms-high-contrast:none) {
    .body-grid-login-admin {
        margin-top: 100px !important;
    }

    .date-filter .ui.input > input {
        height: 35px;
        font-size: 15px;
        border-radius: 0px;
        width: 100%
    }
}

.service-history-message {
    padding-top: 10px;
    padding-bottom: 30px;
    text-align: center;
    margin-top: 18px;
    margin-bottom: 6px;
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    color: #00539F;
}
