.letter-message-data {
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    color: #00539F;
    text-align: left;
    padding-top: 12px;
}

.letter-info-data {
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
    color: #00539F;
    text-align: left;
}

.body-grid-message-data {
    border-width: 0px;
    width: 270px;
    height: 204px;
    background: inherit;
    background-color: rgba(215, 215, 215, 1);
    border: none;
    border-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    background-image: none;
}

.button-circle-message-data {
    width: 139px;
    font-size: 15px;
    height: 42px;
    color: #1453a0;
    border: 1px solid #1453a0;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
}

.button-circle-message-data:focus {
        outline: none;
}



    .button-circle-message-data:hover {
        background-color: #1453a0;
        color: white;
    }
