.body-center-aunthentication {
    margin: auto !important;
    width: 27%;
    padding: 10px !important;
}

.button-authenticated-authentication {
    width: 150px;
    height: 50px;
    margin: 0px;
}

.form-authenticated-authentication {
    width: 100%
}

.letter-data-body-authenticated {
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    color: #00539F;
    text-align: left;
    line-height: 22px;
}

.letter-title-authenticated {
    font-size: 30px;
    color: #00539F;
    text-align: center;
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
}

.center-checks-authenticated {
    justify-content: center !important;
}

.style-btn-cancel-authenticated {
    font-family: "Roboto";
    font-weight: 700;
    color: #00539F;
    text-align: left;
    font-size: 15px;
}

    .style-btn-cancel-authenticated:hover {
        font-family: "Roboto";
        font-weight: 700;
        color: #00539F;
        text-align: left;
        font-size: 15px;
        text-decoration: underline;
    }

.color-radio.ui.radio.checkbox input:checked ~ .box:after, .ui.radio.checkbox input:checked ~ label:after {
    background-color: black !important;
}
