.ui.grid.body {
    margin: 0;
}

    .ui.grid.body > div.row {
        padding-top: 0;
        padding-bottom: 0;
    }

        .ui.grid.body > div.row > div.column {
            padding: 0;
        }

.ui.container {
    background-color: #d7d7d7;
}

.frame {
    padding: 40px;
}

.body-img {
    margin: 0;
    width: 100%;
    min-height: 600px;
    background-image: url("../../img/bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
}

.navbar {
    font-size: 24px;
    padding: 2px;
    text-align: right;
    margin-top: 10px;
    margin-right: 30px;
}

    .navbar a {
        /* text-decoration: none; */
        color: #1453a0;
        font-weight: bolder;
        font-style: italic;
        font-size: 30px;
    }

        .navbar a:hover {
            color: #3a76b7;
        }

@media only screen and (max-width: 480px) {
    .navbar, .navbar a {
        font-size: 12px;
        text-align: center;
        margin-right: 0;
    }

    .frame {
        padding: 0px;
        margin-bottom: 20px;
    }
}

.maintenance-message {
	/* color: #1453a0; */
	color: red;
	margin: 10px;
	padding: 10px;
	text-align: center;
	font-size: 1.5rem;
}


@media only screen and (max-width: 980px) {
    .navbar, .navbar a {
        font-size: 18px;
    }
}


@media only screen and (max-width: 767px) {
    .navbar, .navbar a {
        font-size: 16px;
        text-align: center;
        margin-right: 0;
    }
}
