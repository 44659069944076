.heightCardDescription {
}

.style-grid-icons {
    display: table-cell;
    position: relative;
}

.negative-margin-dashboard {
    margin-left: -15px !important;
}

.icon-button.style-icon.down {
    position: absolute;
    bottom: 0;
    left: 0;
}

.style-icons {
    bottom: 0px;
    position: absolute;
    left: 0;
}

.MuiGrid-container {
    width: 100%;
    display: flex;
    flex-wrap: nowrap !important;
    box-sizing: border-box;
}

.background-color-gray-card {
    background-color: rgba(215, 215, 215, 1) !important;
}

.background-color-white-card {
    background-color: rgba(242, 242, 242, 1) !important;
}

.description {
}

.heightCardInfoDescription {
}

.margin-grid-title-card-info {
    margin-top: 25px !important;
    margin-bottom: 5px !important;
}

.heightTotal {
    height: 100% !important;
}

.subCardTitle {
    min-height: 70px;
    width: 100%;
    white-space: pre-line;
}

.card-shadow {
    -moz-box-shadow: 9px 9px 5px rgba(0, 0, 0, 0.349019607843137);
    -webkit-box-shadow: 9px 9px 5px rgba(0, 0, 0, 0.349019607843137);
    box-shadow: 9px 9px 5px rgba(0, 0, 0, 0.349019607843137) !important;
}

.divider-white {
    background-color: white !important;
    margin-top: 0 !important;
}

.divider-blue-black {
    background-color: #002140 !important;
    margin-top: 0 !important;
}

.ui.cards > .card {
    border-radius: 0 !important;
}

.ui.cards {
    margin: 0;
}

.color-background-cards {
    background-color: #d7d7d7
}

@media screen and (max-width: 1220px) {
    .ui.four.cards {
        margin-left: 1.25em;
        margin-right: -.75em;
    }
}

.letter-title-img-dashboard {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 20px;
    color: #0061A7;
}

    .letter-title-img-dashboard:hover {
        font-family: "Roboto";
        font-weight: 700;
        font-size: 20px;
        color: #0061A7;
        text-decoration: underline;
    }

.card-inner-container {
    height: 200px !important;
}

.padding-height-card-primary {
}

.height-arrows {
    height: 138% !important;
}



@media all and (-ms-high-contrast:none) {
    .subCardTitle p {
        width: 200px;
    }
    .icon-button.style-icon.down, .style-icons {
        top: 200px;
    }
    .ui.cards > .card {
        height: 300px !important;
    }
}

@supports (-ms-ime-align:auto) {
    .ui.cards > .card {
        height: 300px !important;
    }
}
