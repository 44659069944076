.letter-title-header {
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    color: #002140;
    text-decoration: none!important;
}

.margin-responsive-globe {
    margin-left: 15px !important;
}

.select-header {
    width: 90px;
    height: 29px;
    padding: 3px 2px 3px 2px;
    font-family: 'Roboto';
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    letter-spacing: normal;
    color: #000000;
    text-align: left;
    text-transform: none;
    background-color: transparent;
    border-color: black;
}

.globe {
    position: relative;
    top: 5px;
    width: 21px;
    height: 21px;
    margin-left: 13px;
    margin-right: 13px;
}

.align-right-header {
    text-align: right;
}

.style-header{
    background-color: white;
}

.ui.header {
    margin-bottom: 2rem;
}

.letter-title-header {
    font-size: 23px;
}

@media screen and (max-width: 618px) {

    .globe {
        top: 0px;
        margin-top: 10px;
    }
}

@media screen and (max-width: 579px) {

    .responsive-row-globe-select {
        width: 152px;
    }
}

@media screen and (max-width: 1086px) and (min-width: 920px) {
    .margin-responsive-globe {
        margin-left: 30px !important;
    }
    .responsive-row-globe-span {
        padding-left: 100px
    }
}

@media screen and (max-width: 920px) and (min-width: 828px) {

    .margin-responsive-globe {
        margin-left: 25px !important;
    }

    .responsive-row-globe-span {
        padding-left: 77px
    }
}

@media screen and (max-width: 827px) and (min-width: 777px) {
    .margin-responsive-globe {
        margin-left: 15px !important;
    }
    .responsive-row-globe-span {
        padding-left: 62px
    }
}

@media screen and (max-width: 1086px) and (min-width: 920px) {

    .responsive-row-globe-span-admin {
        padding-left: 160px;
        float: right;
    }
}

@media screen and (max-width: 919px) and (min-width: 828px) {

    .responsive-row-globe-span-admin {
        padding-left: 160px;
        float: right;
    }
}

@media screen and (max-width: 827px) {

    .responsive-row-globe-span-admin {
        padding-left: 110px;
        float: right;
    }
}