.letter-name-filter {
    font-family: 'Roboto';
    font-weight: 700;
    color: #00539F;
    text-align: left;
    font-size: 16px;
    margin-right: 5px;
}

@media screen and (min-width: 1356px) {
    .margin-user-audit-log {
        margin-left: 65px !important;
    }
}

@media screen and (max-width: 725px) {
    .responsive-column-date-audit-log {
        width: 31% !important;
    }
}

.view-filter-audit-log-down {
    display: none !important;
}

.view-filter-audit-log-up {
    display: initial !important;
}

@media screen and (max-width: 900px) {
    .view-filter-audit-log-down {
        display: initial !important;
    }

    .view-filter-audit-log-up {
        display: none !important;
    }
    .full-width-resposive {
        width: 100% !important;
        margin-bottom: 15px !important;
    }

    .center-btn-audit-log {
        text-align: center !important;
    }

    .size-btn-audit-log {
        height: 42px;
    }
}

.size-btn-audit-log {
    width: 120px;
    height: 37px;
}

@media screen and (max-width: 552px) {
    .responsive-column-date-audit-log {
        width: 31% !important;
    }
}

@media screen and (max-width: 974px) {
    .date-filter .ui.input {
        width: 154% !important;
    }
}

@media screen and (min-width: 1254px) {

    .margin-width-type {
        margin-left: 36px;
        width: 60.5%;
    }

    .margin-day-to {
        margin-left: 20px;
    }
}

@media screen and (max-width: 1254px) and (min-width: 738px) {
    .margin-width-type {
        margin-left: 0px;
        width: 60.5%;
    }

    .margin-day-to {
        margin-left: 20px;
    }
}


@media screen and (max-width: 738px) {

    .margin-width-type {
        width: 60.5%;
    }

    .margin-day-to {
        margin-left: 30px;
        margin-right: 30px;
    }

    .margin-day-from {
        margin-left: 30px;
        margin-right: 30px;
    }

    .margin-month-to {
        margin-left: 30px;
    }

    .margin-month-from {
        margin-left: 30px;
    }

    .margin-year-to {
        margin-left: 30px;
    }

    .margin-year-from {
        margin-left: 30px;
    }
}

@media screen and (max-width: 734px) and (min-width: 611px) {

    .margin-title-user-audit-log {
        margin-left: 30px;
    }

    .margin-width-type {
        width: 60.5%;
    }
}

@media screen and (max-width: 734px) {
    .margin-title-date-from {
        margin-left: 30px;
    }

    .margin-title-date-to {
        margin-left: 30px;
    }
}

@media screen and (max-width: 724px) {
    .margin-title-date-from {
        margin-left: 10px;
    }

    .margin-title-date-to {
        margin-left: 20px;
    }
}

@media screen and (max-width: 611px) {

    .margin-title-user-audit-log {
        margin-left: 60px;
    }

    .margin-width-type {
        width: 60.5%;
    }
}

@media all and (-ms-high-contrast:none)
{
 
    .date-filter .ui.input > input {
        height: 30px;
        font-size: 15px;
        border-radius: 0px;
    }

}