/* Global Styles */
html,
body,
#root {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    height: 100%;
}

th.letter-title-service-history-list {
    background-color: #ffffff00 !important;
}

* {
    box-sizing: border-box;
}

body {
    /*background-color: #d7d7d7;*/
    font-family: "Raleway", sans-serif;
}

.ui.grid > .row.filters > .column {
    padding-left: 7rem !important;
    padding-right: 2rem !important;
    padding-bottom: 0 !important;
}

.container {
    margin: 0 auto;
}

@media screen and (min-width: 1366px) {
    body {
        background-color: white;
        font-family: "Raleway", sans-serif;
    }

    .container {
        background-color: #d7d7d7;
        width: 950px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1920px) {
    body {
        background-color: white;
        font-family: "Raleway", sans-serif;
    }

    .container {
        background-color: #d7d7d7;
        margin: 0 auto;
        width: 950px;
    }
}

.app-router {
    height: 100%;
}

.app-logo {
    background-image: url("./img/rotechlogo.PNG");
    width: 100px;
    height: 60px;
}

.app-container-max {
    justify-content: center;
    align-items: center;
    background-image: url("./img/bg.png");
    background-size: cover;
    height: 100%;
    width: 100%;
}

.app-container-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("./img/bg.png");
    background-size: cover;
    height: 100%;
    width: 100%;
}

.container-root {
    background-color: whitesmoke;
}

.button-circle {
    width: auto;
    font-size: 15px;
    height: 25px;
    color: #1453a0;
    border: 1px solid #1453a0;
    border-radius: 25px;
    background-color: white;
    cursor: pointer;
}

.button-circle:focus {
	outline: none;
}

    .button-circle:hover {
        background-color: #1453a0;
        color: white;
    }

.ui.table thead {
    -webkit-box-shadow: none;
    box-shadow: none;
}

h1 {
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern" 1;
}

i.icon.mandatoryField {
    color:red;
    font-size: 8px;
    vertical-align: top;
}

p.mandatoryField {
    color:red;
    font-size: 14px;
    font-weight: bold;
}

@media all and (-ms-high-contrast:none)
{
    .body-grid-login-admin {
        margin-top: 100px!important;
    }

    .date-filter .ui.input > input {
        height: 30px;
        font-size: 15px;
        border-radius: 0px;
    }

}

input[type="email"], input[type="tel"] {
    border: 1px solid #888;
}

.profileUnderReview {
    color: red;
    font-size:18px;
}

.ui.compact.table th {
    padding-left: .5em!important;
}
