.form-anauthenticated-authentication-two {
    width: 100%
}

.body-grid-unauthenticated-authentication-two {
    border-width: 0px;
    width: 386px;
    min-height: 310px;
    background: inherit;
    background-color: rgba(215, 215, 215, 1);
    border: none;
    border-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 15px!important;
    margin-bottom: 15px!important;
    background-image: none;
}

.letter-title-unauthenticated-authentication-two {
    font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto';
    font-weight: 700;
    font-style: normal;
    font-size: 30px;
    color: #00539F;
    text-align: center;
}

.letter-data-unauthenticated-authentication-two {
    font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto';
    font-weight: 700;
    color: #000000;
    font-size: 16px;
    padding-left: 45px;
}

.letter-body-unauthenticated-authentication-two {
    font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto';
    font-weight: 700;
    color: #00539F;
    text-align: left;
    font-size: 16px;
    padding-left: 45px;
}


.input-unauthenticated-authentication-two {
    width: 300px;
    height: 25px;
    padding: 3px 2px 3px 2px;
    box-sizing: border-box;
    box-shadow: none;
    background: inherit inherit inherit inherit inherit inherit inherit inherit inherit;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(121, 121, 121);
    border-radius: 0px;
    font-family: Roboto;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    letter-spacing: normal;
    color: rgb(0, 0, 0);
    text-align: left;
    text-transform: none;
    background-color: white;
    padding: 3px 2px;
    margin-left: auto !important;
    margin-right: auto !important;
}


.button-circle-unauthenticated-authentication-two {
    width: 140px;
    font-size: 15px;
    height: 45px;
    color: #1453a0;
    border: 1px solid #1453a0;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
}

.button-circle-unauthenticated-authentication-two:focus {
        outline: none;
}



    .button-circle-unauthenticated-authentication-two:hover {
        background-color: #1453a0;
        color: white;
    }

.letter-cancel-unauthenticated-authentication-two {
    font-size: 15px;
    color: #00539F;
    text-align: left;
    font-family: "Roboto Bold", "Roboto Regular", Roboto;
    font-weight: 700;
    margin-left: 45px;
    margin-top: 15px;
}

    .letter-cancel-unauthenticated-authentication-two:hover {
        text-decoration: underline;
    }

.padding-botton-grid-unauthenticated-authentication-two {
    padding-bottom: 50px !important;
}
