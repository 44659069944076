.font-title-body {
    color: #00539F;
    text-align: left !important;
    font-family: 'Roboto';
    font-weight: 700 !important;
    font-style: normal !important;
    font-size: 20px;
}

.ui.grid {
    margin-left: 0;
}

@media screen and (max-width: 580px) {
    .buttons-profile {
        width: 100% !important;
        margin-top: 10px !important;
    }
}

.font-title {
    font-family: 'Arial Bold', 'Arial';
    font-weight: bold;
    font-style: normal;
    color: #0061A7;
    text-align: left;
    font-size: 30px;
    margin-left: 25px;
    margin: 20px
}

.body-container {
    background-color: rgba(242, 242, 242, 1);
}

.data-body {
    font-family: 'Roboto';
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    letter-spacing: normal;
    color: #000000;
    text-align: left;
    text-transform: none;
    background-color: transparent;
    border-color: transparent;
}

.letter-data-body-upload {
    font-size: 16px;
    color: #00539F;
    text-align: left;
    margin: 0px;
}

.title-upload-card {
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    color: #0061A7;
    text-align: center;
}

.file-upload {
    background-color: #ffffff;
    width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.file-upload-btn {
    width: 100%;
    margin: 0;
    color: #fff;
    background: #1FB264;
    border: none;
    padding: 10px;
    border-radius: 4px;
    border-bottom: 4px solid #15824B;
    transition: all .2s ease;
    outline: none;
    text-transform: uppercase;
    font-weight: 700;
}

    .file-upload-btn:hover {
        background: #1AA059;
        color: #ffffff;
        transition: all .2s ease;
        cursor: pointer;
    }

    .file-upload-btn:active {
        border: 0;
        transition: all .2s ease;
    }

.file-upload-content {
    display: none;
    text-align: center;
}

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.image-upload-wrap {
    margin-top: 20px;
    border: 4px dashed #0083ca;
    position: relative;
}

    .image-dropping,
    .image-upload-wrap:hover {
        background-color: #cce2ff;
    }

.image-title-wrap {
    padding: 0 15px 15px 15px;
    color: #222;
}

.drag-text {
    text-align: center;
    padding: 60px 0
}

    .drag-text h3 {
        padding-right: 40px;
        padding-left: 40px;
        text-transform: uppercase;
        color: #0083ca;
    }

.upload-icon {
    color: #0083ca;
}

.file-upload-image {
    max-height: 200px;
    max-width: 200px;
    margin: auto;
    padding: 20px;
}

.remove-image {
    width: 200px;
    margin: 0;
    color: #fff;
    background: #cd4535;
    border: none;
    padding: 10px;
    border-radius: 4px;
    border-bottom: 4px solid #b02818;
    transition: all .2s ease;
    outline: none;
    text-transform: uppercase;
    font-weight: 700;
}

    .remove-image:hover {
        background: #c13b2a;
        color: #ffffff;
        transition: all .2s ease;
        cursor: pointer;
    }

    .remove-image:active {
        border: 0;
        transition: all .2s ease;
    }

.body-center {
    margin: auto !important;
    width: 60%;
    padding: 10px !important;
}

.title-change-password {
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
    font-size: 30px;
    color: #004280;
}

.letter-data-change-password {
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
    color: #3A76B4;
    margin: 0 0 0em !important;
}

.display-block-grid > div {
    display: block !important;
}

@media screen and (max-width: 883px) and (min-width: 733px) {
    .size-btn-secondary-responsive-profile {
        width: 67%;
    }
}

@media screen and (max-width: 733px) {
    .size-btn-secondary-responsive-profile {
        width: 77% !important;
    }

    .size-btn-primary-responsive-profile {
        width: 67% !important;
    }
}

.color-select-profileA {
    width: 100%;
    border-color: transparent;
    background-color: transparent;
    color: #7F7F7F;
}

.color-input-profileA {
    color: rgb(127, 127, 127);
    border-color: transparent;
    width: 100%;
    background-color: transparent;
}

@media screen and (max-width: 1100px) and (min-width: 768px) {
    #two-column-insurance-profile-admin.grid .size-column-profile {
        width: 50% !important;
        margin-bottom: 25px !important;
    }


    #two-column-emergency-profile-admin.grid .size-column-profile {
        width: 50% !important;
        margin-bottom: 25px !important;
    }

    #two-column-patient-profile-admin.grid .size-column-profile {
        width: 50% !important;
        margin-bottom: 25px !important;
    }
}
