.location {
	margin-left: auto;
	margin-right: auto;
}

@media screen and (max-width: 767px) {
    .padding-info-foouter-unauthenticathed {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
}

@media screen and (min-width: 360px) {
    footer {
        color: #1453a0;
        font-size: 17px;
        font-weight: bold;
    }
}

@media screen and (min-width: 375px) {
    footer {
        margin-left: 15px;
    }
}

@media screen and (min-width: 411px) {
    footer {
        margin-left: 30px;
    }
}

@media screen and (min-width: 768px) {
    footer {
        color: #1453a0;
        font-size: 17px;
        font-weight: bold;
    }
}

@media screen and (min-width: 1024px) {
    footer {
        display: grid;
        color: #1453a0;
        font-size: 17px;
        font-weight: bold;
        margin-right: 0px;
    }

}

@media screen and (min-width: 1366px) {
    footer {
        display: grid;
        font-size: 17px;
        font-weight: bold;
    }

    footer {
        margin: 0;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
    }
}

@media screen and (min-width: 1920px) {
    footer {
        margin: 0;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
    }

}


@media all and (-ms-high-contrast:none)
{
    footer {
        padding-bottom: 100px;
    }

}
