.letter-error-create-account {
    padding-left: 40px;
    padding-right: 40px;
    color: #B50808;
    text-align: left;
}

.margin-error-create-account {
    margin-top: -5px;
    margin-bottom: -15px;
    margin-left: 40px;
    margin-right: 40px;
}

.margin-error-create-account > .error-text {
    text-align: center;
}

.recaptcha-create-account {
    transform: scale(0.72);
}

.height-grid-error-create-account {
    height: 581px !important;
}

.label-disabled {
    color: #bbbbbb !important;
}

.body-grid-create-account {
    border-width: 0px;
    width: 330px;
    min-height: 560px;
    background: inherit;
    background-color: rgba(215, 215, 215, 1);
    border: none;
    border-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    background-image: none;
}

.letter-body-create-account {
    font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto';
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
    color: #00539F;
    text-align: left;
    padding-left: 40px;
}

.letter-message-create-account {
    font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto';
    font-style: normal;
    font-size: 16px;
    color: #00539F;
    text-align: left;
    padding-left: 40px;
    padding-right: 40px;
}

.letter-title-create-account {
    font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto';
    font-weight: 700;
    font-style: normal;
    font-size: 30px;
    color: #00539F;
    text-align: center;
    padding-top: 20px;
}

.select-date-create-account {
    width: 62px;
    height: 32px;
    font-size: 14px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(121, 121, 121, 1);
    border-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 14px;
}

.padding-horizontal-date-create-account {
    margin-left: auto !important;
    margin-right: auto !important;
    display: flex;
    width: 100%;
    padding-left: 40px;
}

.input-create-account {
    width: 253px;
    height: 40px;
    box-sizing: border-box;
    box-shadow: none;
    background: inherit inherit inherit inherit inherit inherit inherit inherit inherit;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(121, 121, 121);
    border-radius: 0px;
    font-family: Roboto;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    letter-spacing: normal;
    color: rgb(0, 0, 0);
    text-align: left;
    text-transform: none;
    background-color: white;
    padding: 3px 2px;
    margin-left: auto !important;
    margin-right: auto !important;
}

.letter-cancel-create-account {
    font-size: 15px;
    color: #00539F;
    text-align: left;
    font-family: "Roboto Bold", "Roboto Regular", Roboto;
    font-weight: 700;
    margin-left: 45px;
    margin-top: 15px;
}

    .letter-cancel-create-account:hover {
        text-decoration: underline;
    }

.button-circle-create-account {
    width: 100px;
    font-size: 15px;
    height: 45px;
    color: #1453a0;
    border: 1px solid #1453a0;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
}

.button-circle-create-account:focus {
	outline: none;
}

    .button-circle-create-account:hover {
        background-color: #1453a0;
        color: white;
    }


.letter-black-create-account {
    color: rgb(51, 51, 51);
    text-align: center;
    font-family: "Roboto Bold", "Roboto Regular", Roboto;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    margin-right: 3px;
}

.letter-redirect-login-create-account {
    font-family: "Roboto Bold Italic", "Roboto Regular", Roboto;
    font-weight: 700;
    font-style: italic;
    color: rgb(58, 118, 180);
    text-align: center;
    overflow-wrap: break-word;
    text-transform: none;
    font-size: 16px;
}

p.maintenance-message {
        margin: 50px 20px;
        font-size: 1.25rem;
	color: red;
}


.mb-2 {
    margin-bottom : 15px;
}
