.logo img {
    height: 105px;
}

.home-title {
    line-height: 105px;
    color: #002140;
    font-size: 35px !important;
}

.text-aling-welcome-header-unauthenticated {
    text-align: right;
}

.ui.grid.unauthenticatedHeader {
    border-bottom: 1px solid black;
    margin: 0;
}

    .ui.grid.unauthenticatedHeader > div.column {
        padding-top: 0;
        padding-bottom: 0;
    }

@media only screen and (max-width: 480px) {
    .logo img {
        height: 60px;
    }

    .navbar {
        font-size: 12px;
    }
}

@media only screen and (max-width: 1200px) {

    .size-column-title-unauthenticated-header {
        width: 100% !important;
    }

    .text-aling-welcome-header-unauthenticated {
        text-align: center;
    }

    .logo {
        text-align: center;
    }

    .home-title {
        font-size: 34px !important;
    }
}


@media only screen and (max-width: 767px) {
    .ui.stackable.grid > .column:not(.row) {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .logo {
        text-align: center;
    }
}
