.letter-upload-click {
    font-kerning: normal;
    white-space: nowrap;
    text-transform: none;
    font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto';
    font-weight: 700;
    font-style: normal;
    color: #333333;
}

.size-column-column-profile {
    width: 20%;
}

.two-column-profile {
    width: 25% !important;
}

.width-column-tile-profile {
    width: -1% !important;
}

.display-error-profile-up {
    display: initial !important;
}

.display-error-profile-down {
    display: none !important;
    margin-left: 35px;
}

.margin-message-error-upload-manual {
    margin-top: -10px !important;
    margin-bottom: 15px !important;
}

@media screen and (max-width: 580px) {
    max-width-column-buttons-profile {
        width: 100% !important;
        margin-top: 10px !important;
    }
}

@media screen and (max-width: 1260px) {
    .display-error-profile-up {
        display: none !important;
    }

    .display-error-profile-down {
        display: initial !important;
        margin-left: 35px;
    }
}

@media screen and (max-width: 812px) {
    .width-column-tile-profile {
        width: 52% !important;
    }
}

.profileManualUploadForm {
    width: 100%
}

input#effectiveDateUpload {
    width: 184% !important;
    max-width: 184% !important;
}

@media screen and (max-width: 974px) {
    input#effectiveDateUpload {
        width: 65% !important;
        max-width: 65% !important;
    }

    .padding-responsive-text-upload-manual {
        padding-left: 150px !important;
        padding-right: 150px !important;
    }
}

@media screen and (max-width: 724px) {
    input#effectiveDateUpload {
        width: 100% !important;
        max-width: 100% !important;
    }

    .padding-responsive-text-upload-manual {
        padding-left: 100px !important;
        padding-right: 100px !important;
    }

    .profileGridAddress .ui.column.grid > [class*="eight wide"].column, .ui.grid > .column.row > [class*="eight wide"].column, .ui.grid > .row > [class*="eight wide"].column, .ui.grid > [class*="eight wide"].column {
        width: 100% !important;
    }
}

.padding-datepicker-manual-upload {
    padding-bottom: 12px !important;
    margin-right: auto !important;
    margin-left: auto !important;
}

.title-upload-manual {
    font-kerning: normal;
    text-transform: uppercase;
    font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto';
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: 24px;
    color: #002140;
}

.margin-rows-upload-manual {
    padding-top: 15px !important;
    margin-bottom: 20px !important;
}

.buttons-upload-manual {
    width: 138px;
    height: 37px;
}

.letter-upload-manual {
    font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto';
    font-weight: 700;
    text-rendering: optimizeLegibility;
    font-kerning: normal;
    text-transform: none;
    font-style: normal;
    font-size: 16px;
    color: #00539F;
    text-align: left;
}

.padding-responsive-text-upload-manual {
    padding-left: 300px;
    padding-right: 300px;
}

.profile-edit-form {
    width: 100%
}

.font-title-body {
    color: #00539F;
    text-align: left !important;
    font-family: 'Roboto';
    font-weight: 700 !important;
    font-style: normal !important;
    font-size: 20px;
}

.ui.grid {
    margin-left: 0;
}

.font-title {
    font-family: 'Arial Bold', 'Arial';
    font-weight: bold;
    font-style: normal;
    color: #0061A7;
    text-align: left;
    font-size: 30px;
    margin-left: 25px;
    margin: 20px;
    line-height: 30px;
}

.body-container {
    margin-left: 13px !important;
    margin-right: 13px !important;
    background-color: rgba(242, 242, 242, 1);
}

.data-body {
    font-family: 'Roboto';
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    letter-spacing: normal;
    color: #7F7F7F;
    text-align: left;
    text-transform: none;
    background-color: transparent;
    border-color: transparent;
}

.letter-data-body-upload {
    font-size: 16px;
    color: #00539F;
    text-align: left;
    margin: 0px;
}

.title-upload-card {
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    color: #0061A7;
    text-align: center;
}

.title-upload-card-confirm {
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    line-height: 30px;
    color: #0061A7;
    text-align: center;
}


.max-width {
    width: 100% !important;
}

.file-upload {
    background-color: #ffffff;
    width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.file-upload-btn {
    width: 100%;
    margin: 0;
    color: #fff;
    background: #1FB264;
    border: none;
    padding: 10px;
    border-radius: 4px;
    border-bottom: 4px solid #15824B;
    transition: all .2s ease;
    outline: none;
    text-transform: uppercase;
    font-weight: 700;
}

    .file-upload-btn:hover {
        background: #1AA059;
        color: #ffffff;
        transition: all .2s ease;
        cursor: pointer;
    }

    .file-upload-btn:active {
        border: 0;
        transition: all .2s ease;
    }

.file-upload-content {
    display: none;
    text-align: center;
}

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.image-upload-wrap {
    margin-top: 20px;
    border: 4px dashed #0083ca;
    position: relative;
}

    .image-dropping,
    .image-upload-wrap:hover {
        background-color: #cce2ff;
    }

.image-title-wrap {
    padding: 0 15px 15px 15px;
    color: #222;
}

.drag-text {
    text-align: center;
    padding: 60px 0
}

    .drag-text h3 {
        padding-right: 40px;
        padding-left: 40px;
        text-transform: uppercase;
        color: #0083ca;
    }

.upload-icon {
    color: #0083ca;
}

.file-upload-image {
    max-height: 200px;
    max-width: 200px;
    margin: auto;
    padding: 20px;
}

.remove-image {
    width: 200px;
    margin: 0;
    color: #fff;
    background: #cd4535;
    border: none;
    padding: 10px;
    border-radius: 4px;
    border-bottom: 4px solid #b02818;
    transition: all .2s ease;
    outline: none;
    text-transform: uppercase;
    font-weight: 700;
}

    .remove-image:hover {
        background: #c13b2a;
        color: #ffffff;
        transition: all .2s ease;
        cursor: pointer;
    }

    .remove-image:active {
        border: 0;
        transition: all .2s ease;
    }

.body-center {
    margin: auto !important;
    width: 60%;
    padding: 10px !important;
}

    .body-center.confirm {
        margin: auto !important;
        width: 60%;
        padding-top: 150px !important;
        padding-bottom: 150px !important;
    }


.title-change-password {
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
    font-size: 30px;
    color: #004280;
}

.letter-data-change-password {
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
    color: #3A76B4;
    margin: 0 0 0em !important;
}

.display-block-grid > div {
    display: block !important;
}

@media screen and (max-width: 652px) {
    .buttons-profile {
        margin-right: 30px;
    }
}

@media screen and (max-width: 883px) and (min-width: 733px) {
    .margin-responsive-buttons-profile {
        margin-right: 20px;
    }
}

@media screen and (max-width: 733px) {
    .margin-responsive-buttons-profile {
        margin-right: 30px;
    }

    .size-btn-primary-responsive-profile {
        width: 67% !important;
    }
}

@media screen and (max-width: 1033px) {
    .column-responsive-email-address-profile {
        width: 40% !important;
    }
}

@media screen and (max-width: 533px) {
    .column-responsive-email-address-profile {
        width: 45% !important;
    }
}

@media screen and (max-width: 1033px) {
    .column-responsive-policy-number-profile {
        width: 22% !important;
    }
}

.notAbleToUploadLink:hover {
    cursor: pointer;
    text-decoration: underline;
}

.profileRow.ui.grid {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
}

.changePasswordProfile {
    width: 400px !important;
}

    .changePasswordProfile input {
        width: 100% !important;
    }

    .changePasswordProfile .row {
        margin-top: 10px;
    }

        .changePasswordProfile .row.title {
            margin-top: 10px;
            margin-bottom: 20px;
        }


@media all and (-ms-high-contrast:none) {
    .date-filter .ui.input > input {
        height: 30px;
        font-size: 15px;
        border-radius: 0px;
    }

    .button-circle.profile-button {
        min-width: 137px;
        max-width: auto;
        min-height: 38px;
    }
}


@media screen and (max-width: 1100px) and (min-width: 768px) {
    #two-column-insurance-profile.grid .size-column-profile {
        width: 50% !important;
        margin-bottom: 25px !important;
    }


    #two-column-emergency-profile.grid .size-column-profile {
        width: 50% !important;
        margin-bottom: 25px !important;
    }

    #two-column-patient-profile.grid .size-column-profile {
        width: 50% !important;
        margin-bottom: 25px !important;
    }
}


.button-circle.profile-button {
    min-width: 120px;
    max-width: 120px;
    min-height: 38px;
    padding-right: 10px;
    padding-left: 10px;
}
