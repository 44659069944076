.letter-error-reset-account-password {
    font-kerning: normal;
    color: #B50808;
    text-align: left;
    font-family: 'Roboto';
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    letter-spacing: normal;
    padding-left: 33px !important;
}

.resetPasswordForm {
    width: 100%;
}

.body-grid-reset-account-password {
    border-width: 0px;
    width: 313px;
    min-height: 456px;
    background: inherit;
    background-color: rgba(215, 215, 215, 1);
    border: none;
    border-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    background-image: none;
}

.padding-row-requeriment {
    padding-left: 20px
}

.letter-body-reset-account-password {
    font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto';
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
    color: #00539F;
    text-align: left;
    padding-left: 30px;
}

.letter-title-reset-account-password {
    font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto';
    font-weight: 700;
    font-style: normal;
    font-size: 30px;
    color: #00539F;
    text-align: center;
    padding-top: 20px;
}

.letter-requeriment-title-reset-account-password {
    font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto';
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    color: #00539F;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

.letter-requeriment-reset-account-password {
    font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto';
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    color: #00539F;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-left: 30px;
}

.input-reset-account-password {
    width: 253px;
    height: 40px;
    box-sizing: border-box;
    box-shadow: none;
    background: inherit inherit inherit inherit inherit inherit inherit inherit inherit;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(121, 121, 121);
    border-radius: 0px;
    font-family: Roboto;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    letter-spacing: normal;
    color: rgb(0, 0, 0);
    text-align: left;
    text-transform: none;
    background-color: white;
    padding: 3px 2px;
    margin-left: auto !important;
    margin-right: auto !important;
}

.button-circle-reset-account-password {
    width: 100px;
    font-size: 15px;
    height: 45px;
    color: #1453a0;
    border: 1px solid #1453a0;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
}

.button-circle-reset-account-password:focus {
        outline: none;
}



    .button-circle-reset-account-password:hover {
        background-color: #1453a0;
        color: white;
    }

.letter-cancel-reset-account-password {
    font-size: 15px;
    color: #00539F;
    text-align: left;
    font-family: "Roboto Bold", "Roboto Regular", Roboto;
    font-weight: 700;
    margin-left: 45px;
    margin-top: 15px;
}

    .letter-cancel-reset-account-password:hover {
        text-decoration: underline;
    }

.letter-black-reset-account-password {
    color: rgb(51, 51, 51);
    text-align: center;
    font-family: "Roboto Bold", "Roboto Regular", Roboto;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    margin-right: 3px;
}

.letter-redirect-login-reset-account-password {
    font-family: "Roboto Bold Italic", "Roboto Regular", Roboto;
    font-weight: 700;
    font-style: italic;
    color: rgb(58, 118, 180);
    text-align: center;
    overflow-wrap: break-word;
    text-transform: none;
    font-size: 16px;
}
