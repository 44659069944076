@media screen and (min-width: 960px) {
    .width-affected-user {
        width: 45.5% !important;
    }

    .margin-width-role {
        margin-left: 46px !important;
        width: 45% !important;
    }

    .margin-patient {
        margin-left: 29px !important;
    }

    .margin-width-status {
        margin-left: 53px !important;
        width: 45% !important;
    }
}

.view-btn-filter-manage-user {
    display: none !important;
}

.view-btn-filter-manage-user-computer {
    display: initial !important;
}

@media screen and (max-width: 767px) {
    .view-btn-filter-manage-user {
        display: initial !important;
    }

    .view-btn-filter-manage-user-computer {
        display: none !important;
    }
}

@media screen and (max-width: 787px) {
    .width-input-username-filter-manage {
        width: 100%;
    }

    .width-select-patientId-filter-manage {
        width: 100%;
    }

    .margin-patient {
        margin-left: 0px !important;
    }

    .margin-width-role {
        margin-left: 0px !important;
        width: 100% !important;
    }

    .margin-width-status {
        margin-left: 0px !important;
        width: 100% !important;
    }
}
