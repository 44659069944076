.letter-body-login {
    font-size: 20px;
    color: #00539F;
    text-align: left;
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
    padding-left: 39px;
}

.login-title {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 30px;
    color: #00539F;
    text-align: center;
    padding-top: 30px;
}

.body-grid-login {
    border-width: 0px;
    width: 313px;
    height: 504px;
    background: inherit;
    background-color: rgba(215, 215, 215, 1);
    border: none;
    border-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    background-image: none;
}

.button-circle-login {
    width: 100px;
    font-size: 15px;
    height: 45px;
    color: #1453a0;
    border: 1px solid #1453a0;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
}

.button-circle-login:focus {
        outline: none;
}

    .button-circle-login:hover {
        background-color: #1453a0;
        color: white;
    }

.input-login {
    width: 253px;
    height: 40px;
    box-sizing: border-box;
    box-shadow: none;
    background: inherit inherit inherit inherit inherit inherit inherit inherit inherit;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(121, 121, 121);
    border-radius: 0px;
    font-family: Roboto;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    letter-spacing: normal;
    color: rgb(0, 0, 0);
    text-align: left;
    text-transform: none;
    background-color: white;
    padding: 3px 2px;
    margin-left: auto !important;
    margin-right: auto !important;
}


.letter-forgot-login {
    font-size: 16px;
    font-family: "Roboto Bold Italic", "Roboto Regular", Roboto;
    font-weight: 700;
    font-style: italic;
    color: rgb(58, 118, 180);
    padding-left: 39px;
    text-align: left;
}

    .letter-forgot-login:hover {
        text-decoration: underline;
    }
    
.letter-new-login {
    color: rgb(51, 51, 51);
    text-align: center;
    font-family: "Roboto Bold", "Roboto Regular", Roboto;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    margin-right: 18px;
}

.letter-create-account-login {
    font-family: "Roboto Bold Italic", "Roboto Regular", Roboto;
    font-weight: 700;
    font-style: italic;
    color: rgb(58, 118, 180);
    text-align: center;
    overflow-wrap: break-word;
    text-transform: none;
    font-size: 16px;
}

    .letter-create-account-login:hover {
        text-decoration: underline;
    }

.letter-cancel-login {
    font-size: 15px;
    color: #00539F;
    text-align: left;
    font-family: "Roboto Bold", "Roboto Regular", Roboto;
    font-weight: 700;
    margin-left: 45px;
    margin-top: 15px;
}

    .letter-cancel-login:hover {
        font-size: 15px;
        color: #00539F;
        text-align: left;
        font-family: "Roboto Bold", "Roboto Regular", Roboto;
        font-weight: 700;
        margin-left: 45px;
        margin-top: 15px;
        text-decoration: underline;
    }

p.maintenance-message {
	margin: 50px 20px;
	font-size: 1.25rem;
	color: red;
}


@media all and (-ms-high-contrast:none) {
    .ui.one.column.grid.body-grid-login {
        margin-top: 45px !important;
    }
}
