.font-button-footer {
    font-weight: 700;
    font-size: 20px;
    color: white;
}

    .font-button-footer:hover {
        color: rgb(0,255,255);
    }

@media screen and (min-width: 730px) {
    .line-height-footer {
        line-height: 0.4285em !important;
    }
}

@media screen and (max-width: 618px) {

    .font-button-footer {
        font-size: 14px;
    }    
}


@media screen and (max-width: 730px) {
    .line-height-footer {
        line-height: 1.4285em !important;
    }
}

.margin-top-footer {
    margin-top: 50px !important;
}

.style-text-footer {
    margin-top: 18px;
    margin-bottom: 6px;
    text-align: center;
    font-family:  'Roboto';
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    color: #00539F;
}
