.color-no-margin-header {
    margin-top: calc(1.2rem - 0.142857em) !important;
    background-color: #002140;
}

.text-aling-sign-out-navbar {
    text-align: right !important
}

.text-aling-sign-out-navbar-two {
    text-align: right !important
}

.width-service-history-navbar {
    width: 26% !important;
}

.font-button-navbar {
    font-weight: 600;
    font-size: 26px;
    color: white;
    cursor: pointer;
}

    .font-button-navbar:hover {
        color: rgb(0,255,255);
    }

.position-button-navbar {
    margin-right: 40px
}

@media screen and (min-width: 1091px) {
    .size-column-historial-servicio {
        width: 37% !important;
    }
}

@media screen and (min-width: 787px) {
    .size-column-spanish {
        width: 405px !important;
    }
}

@media screen and (max-width: 1244px) and (min-width: 787px) {
    .width-service-history-navbar {
        width: 345px !important;
    }
}

@media screen and (max-width: 1090px) {
    .size-column-historial-servicio {
        width: 345px !important;
    }
}


@media screen and (min-width: 878px) {
    .max-width-navbar {
        width: 100% !important
    }

    .button-selected-navbar {
        font-weight: 600;
        font-size: 26px;
        color: #002140;
        background-color: white;
        border-width: 2px 2px 0px;
        border-style: solid solid solid;
        border-color: rgb(0, 83, 159) rgb(0, 83, 159) rgb(0, 83, 159);
        border-bottom: 0px;
        border-radius: 10px 10px 0px 0px;
        padding-bottom: 14px;
        padding-top: 11px;
        padding-left: 15px;
        padding-right: 15px;
        border-bottom: 1px solid white;
    }

        .button-selected-navbar:hover {
            color: #002140;
        }
}

@media screen and (min-width: 787px) {
    .button-selected-navbar-two {
        font-weight: 600;
        font-size: 26px;
        color: #002140;
        background-color: white;
        border-width: 2px 2px 0px;
        border-style: solid solid solid;
        border-color: rgb(0, 83, 159) rgb(0, 83, 159) rgb(0, 83, 159);
        border-bottom: 0px;
        border-radius: 10px 10px 0px 0px;
        padding-bottom: 14px;
        padding-top: 11px;
        padding-left: 15px;
        padding-right: 15px;
        border-bottom: 1px solid white;
    }

        .button-selected-navbar-two:hover {
            color: #002140;
        }
}

@media screen and (min-width: 1090px) {
    .width-dashboard-navbar {
        width: 18.5% !important
    }

    .width-profile-navbar {
        width: 12.5% !important;
    }

    .width-manage-navbar-two {
        width: 26.75% !important;
    }

    .width-audit-navbar-two {
        width: 21.25% !important;
    }
}

@media screen and (max-width: 915px) and (min-width: 787px) {
    .button-selected-navbar {
        font-size: 20px;
    }

    .font-button-navbar {
        font-size: 20px;
    }

    .width-manage-navbar-two {
        width: 36.75% !important;
    }

    .width-audit-navbar-two {
        width: 30% !important;
    }
}

@media screen and (max-width: 787px) {

    .row-selected-navbar-two {
        background-color: white;
        border-width: 2px 2px 0px;
        border-style: solid solid solid;
        border-color: rgb(0, 83, 159) rgb(0, 83, 159) rgb(0, 83, 159);
    }

    .button-selected-navbar {
        font-size: 20px;
    }

    .font-button-navbar {
        font-size: 20px;
    }


    .width-manage-navbar-two {
        width: 100% !important;
    }

    .width-audit-navbar-two {
        width: 100% !important;
    }

    .size-column-spanish {
        width: 100% !important;
    }
}

@media screen and (max-width: 877px) {
    .text-aling-sign-out-navbar {
        text-align: left !important
    }
}

@media screen and (max-width: 787px) {
    .text-aling-sign-out-navbar-two {
        text-align: left !important;
        margin-left: inherit !important
    }
}


@media screen and (max-width: 1089px) and (min-width: 877px) {
    .button-selected-navbar {
        font-size: 20px;
    }

    .font-button-navbar {
        font-size: 20px;
    }


    .ui.column.grid > [class*="three wide"].column, .ui.grid > .column.row > [class*="three wide"].column, .ui.grid > .row > [class*="three wide"].column, .ui.grid > [class*="three wide"].column {
        width: 100% !important;
    }

    .width-dashboard-navbar {
        width: 19.75% !important
    }

    .width-profile-navbar {
        width: 15.75% !important
    }
}

@media screen and (max-width: 846px) and (min-width: 618px) {

    .button-selected-navbar {
        font-size: 20px;
    }

    .font-button-navbar {
        font-size: 20px;
    }


    .width-dashboard-navbar {
        width: 26.75% !important;
    }

    .width-profile-navbar {
        width: 19.75% !important
    }
}

@media screen and (max-width: 877px) {

    .row-selected-navbar {
        background-color: white;
        border-width: 2px 2px 0px;
        border-style: solid solid solid;
        border-color: rgb(0, 83, 159) rgb(0, 83, 159) rgb(0, 83, 159);
    }

    .font-button-navbar {
        font-size: 20px;
    }

    .width-dashboard-navbar {
        width: 100% !important;
    }

    .width-profile-navbar {
        width: 100% !important
    }

    .width-service-history-navbar {
        width: 100% !important
    }

    .size-column-historial-servicio {
        width: 100% !important
    }

    .width-sign-out-navbar {
        width: 100% !important;
        cursor:pointer;
    }

    .ui.column.grid > [class*="three wide"].column, .ui.grid > .column.row > [class*="three wide"].column, .ui.grid > .row > [class*="three wide"].column, .ui.grid > [class*="three wide"].column {
        width: 100% !important;
    }
}


.text-right {
    text-align: right;
}
