.letter-message-confirm {
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    color: #00539F;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
}

.body-grid-message-confirm {
    border-width: 0px;
    width: 386px;
    height: 355px;
    background: inherit;
    background-color: rgba(215, 215, 215, 1);
    border: none;
    border-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    background-image: none;
}

.button-circle-message-confirm {
    width: 139px;
    font-size: 15px;
    height: 42px;
    color: #1453a0;
    border: 1px solid #1453a0;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
}

.button-circle-message-confirm:focus {
        outline: none;
}


    .button-circle-message-confirm:hover {
        background-color: #1453a0;
        color: white;
    }
