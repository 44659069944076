.grid-anauthenticated-authentication {
    background-color: #d7d7d7;
    width: 400px;
    color: #1453a0;
    padding: 33px !important;
    margin-left: auto !important;
    margin-right: auto !important
}

.form-anauthenticated-authentication {
    width: 100%
}

.anauthenticated-authentication {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    background-image: none;
}
